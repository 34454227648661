header {
    background-color: $white;
    padding: 30px 15px;
    @include breakpoint(medium down) { padding: 0px 15px; }

    p.company-hours {
        color: $green;
        font-family: $roboto;

        @include breakpoint(medium) { font-size: rem-calc(18) };
        @include breakpoint(large) { font-size: rem-calc(20) };
    }

    h3.farm-taste {
        margin-top: 15px;
        @include breakpoint(medium) { font-size: rem-calc(35); };
    }
    img.logo { @include breakpoint(small only) { max-width:80%; }; }

    &> .row:first-of-type {
       @include breakpoint(medium down) { margin: 15px 0px; };
    }
	
}

.title-bar { @include breakpoint(small) { margin-left: -25px; margin-right: -25px; }; @include breakpoint(medium) { margin-left: -30px; margin-right: -30px; }; } 
 
section.banner-area {
    width: 100%;
    margin: 0px auto;
    padding: 115px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 

    h1 { color: $white !important; margin: 0 auto; }

    &.homepage {
        background: $white;
        padding: 0px 0;
        position: relative; 
        text-align: center;

        .header-image { position: absolute; bottom: 80px; text-align: center; left: 0; right: 0; margin-left: auto; margin-right: auto; }
        .header-image h1 { margin-bottom: 30px; }
        .header-image a { display: inline-block; }
    }
    &.whiterip:before {
        content: "";
        background-image: url(../img/ripped-white.png) !important;
        background-repeat: repeat-x !important;
        background-position: 50% 0% !important;
        width: 100%;
        height: 50px;
        background-color: transparent;
        position: absolute;
        z-index: 999;
        left: 0;
        top:0;
        display: none;
        @include breakpoint(large) { display: block; }
    }
}

div#mobile-menu {
    padding: 0;
    z-index: 900;
    position: absolute;
    width: 100%;

    @include breakpoint(small) { margin-left: -25px; margin-right: -25px; };
    @include breakpoint(medium) { margin-left: -30px; margin-right: -30px; };
}
div#mobile-menu .is-drilldown, div#mobile-menu .top-bar-right{
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
}
div#mobile-menu ul.drilldown {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    background-color: $babysick;
    border-top: 5px solid #fff;
}
div#mobile-menu ul.drilldown li {
    text-align:left;
    padding:7px 15px;
    width: 100% !important;
    min-width: 100% !important;
    margin: 0;
}
div#mobile-menu ul.drilldown li a {
    color: $white !important;
}