@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/*-----  Padding & Margins  -----*/
h1, h2, h3, h4 { font-family: $calisto !important; color: $green !important; }

.f17 p {
	@include breakpoint(small) { font-size: rem-calc(16) };
	@include breakpoint(medium) { font-size: rem-calc(16) };
	@include breakpoint(large) { font-size: rem-calc(17) };
}
.f19 p {
	@include breakpoint(small) { font-size: rem-calc(17) };
	@include breakpoint(medium) { font-size: rem-calc(18) };
	@include breakpoint(large) { font-size: rem-calc(19) };
}
.f20 p {
	@include breakpoint(small) { font-size: rem-calc(17) };
	@include breakpoint(medium) { font-size: rem-calc(18) };
	@include breakpoint(large) { font-size: rem-calc(20) };
}
.f26 p {
	@include breakpoint(small) { font-size: rem-calc(18) };
	@include breakpoint(medium) { font-size: rem-calc(22) };
	@include breakpoint(large) { font-size: rem-calc(26) };
}
.f30 p {
	@include breakpoint(small) { font-size: rem-calc(18) };
	@include breakpoint(medium) { font-size: rem-calc(24) };
	@include breakpoint(large) { font-size: rem-calc(30) };
}
.f40 p, .f40 h2 {
	@include breakpoint(small) { font-size: rem-calc(28) };
	@include breakpoint(medium) { font-size: rem-calc(34) };
	@include breakpoint(large) { font-size: rem-calc(40) };
}

@each $size in ( 10,20, 30, 40, 50, 60, 120 ) {
	.pad#{$size} {
	padding-top: rem-calc($size); 
	padding-bottom: rem-calc($size); 
	}
	.padall#{$size} {
	padding: rem-calc($size); 
	@include breakpoint(medium down) { padding: rem-calc($size / 2); }
	}
	.padbot#{$size} {
	padding-bottom: rem-calc($size); 
	@include breakpoint(medium down) { padding-bottom: rem-calc($size / 2); }
	}
	.padtop#{$size} {
	padding-top: rem-calc($size); 
	@include breakpoint(medium down) { padding-top: rem-calc($size / 2); }
	}
	.marbot#{$size} {
	margin-bottom: rem-calc($size); 
	}
	.martop#{$size} {
	margin-top: rem-calc($size); 
	}
}
.small-padtop30 { @include breakpoint(small only) { padding-top: rem-calc(30); } }
h3 { font-family: $caveat !important; color: $babysick !important; }
.owl-controls {
		height: 30px;
		@extend .martop20;
		@extend .marbot20;
	}
	.owl-dots {
		height: 30px;
		text-align:center;
	}
	.owl-dot {
		height: 20px;
		width: 20px;
		background-color:$white;
		border-radius: 50%;
		display:inline-block;
		margin:5px
	}
	.owl-dot.active {
		background-color: $green;
	}
#main-carousel .owl-controls { display:none; }

/*-----  Utility classes  -----*/
.vcparent {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position:relative
}
.relative { position: relative; }
.stack-margin { @include breakpoint(small only) { margin-top: rem-calc(20)}; }
.bgimage { background-repeat: no-repeat; background-size: cover;}
.bgimgblck { background-repeat: no-repeat; background-position: 5% 50%; @include breakpoint(medium down) { background-position: 50% 10%; } } 
.p220 { min-height: 200px; }
.posx { background-position: 50%; }
.post { background-position: 50% 0%; }
.vcenter { @include vertical-align; }
.maxheight { height: 100%; }
.centerall { @include absolute-center; }
.thumbnail { border: 0px !important;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) !important;
    cursor: pointer;}
 

	@include breakpoint(small only) { .smpad { margin-top: rem-calc(30); } }
p.intro {
    border: 4px solid $light-gray;
    border-width: 4px 0;
    padding: 20px 0;
    font-size: 24px;
    margin: 30px auto;

     @include breakpoint(small only) {font-size: rem-calc(18)};
}

/*-----  backgrounds  -----*/
.bgwhite {
	background-color: $white; 

	p:last-of-type { margin-bottom: 0; }
}

.bgcream {
	background-color: $cream; 
	h1, h2 { color: $green; }
	p { color: #000; }
}

.bggreen {
	background-color: $green; 
	p, h2 { color: $white; }
}


.whitetriangle:before { @include css-triangle(30px, $white, down); margin:0 auto; margin-bottom: rem-calc(30); border-width:27px 36.5px 0 36.5px;}
.creamtriangle:before { @include css-triangle(30px, $cream, down); margin:0 auto; margin-bottom: rem-calc(30); border-width:27px 36.5px 0 36.5px;}

.accordion-content {
    color: inherit!important;
}
.creamrip { background-image: url(../img/ripped-beige.png); background-repeat: repeat-x; background-position: 50% 0%; }
.whiterip { background-image: url(../img/ripped-white.png); background-repeat: repeat-x; background-position: 50% 0%; }
.creambtn { background-color: #ede7ca !important; }
.creambtn:hover { background-color: $cream !important; }


.padsmall { padding-top:20px; @include breakpoint(medium) { padding-top:0px; } }
section.breadcrumb { @extend .pad40;
	a { color: $green !important; text-decoration: none !important; } 
	span.breadcrumb-separator { display: inline-block; padding: 0px 10px; }
 }  


aside.sidebar {
	p.newstitle, p.chatter {
    margin-top: rem-calc(20);
    font-size: rem-calc(22);
    padding-right:0px;
	}
	p.chatter a{
    margin-top: rem-calc(20);
    color: $green !important;
	}
	.journal {
		h2 { color: $green; margin-bottom:1rem;  }
		a.button { background-color: $cream; }
	}
	.menu a { font-family: $calisto; font-size: rem-calc(23); color: $white !important; text-decoration: none !important; }
	.menu li:after {
	    content: '';
	    display: block;
	    margin: auto;
	    height: 3px;
	    width: 0;
	    background: rgba(0, 0, 0, 0);
	    transition: width .5s ease,background-color .5s ease;
	    margin-top: 3px;
	}
	.menu li.active:after, .menu li:hover:after {
    	width: 100%;
		background: rgba(255, 255, 255, 0.3);
	}
	& > div {
    	margin-top: rem-calc(40);
	}
	& > div:first-of-type {
    	margin-top: 0px;
	}
}

.close-button {
    color: #900 !important;
    /* background-color: #fff; */
    width: rem-calc(40);
    padding-top: 3px;
    font-size: 3rem;
}

p.image-text {
    background: $yellow; 
    max-width: rem-calc(308);
    padding: 6px 10px;
    text-align: center;
    margin: 0 auto;
}

/*-----  HTML  -----*/
	a { color: $green !important; text-decoration: underline !important; } 

	#mapcanvas {
	    position: relative;
	    height: rem-calc(460) !important;
	    min-height: rem-calc(460) !important;
	    max-height: rem-calc(460) !important;
	    padding-bottom: 1%;
	    margin-top: rem-calc(10);
	    overflow: hidden;

	    &.bigger {
		    position: relative;
		    height: rem-calc(700) !important;
		    min-height: rem-calc(700) !important;
		    max-height: rem-calc(700) !important;
		    padding-bottom: 1%;
		    margin-top: rem-calc(10);
		    overflow: hidden;
		}
	}

	ul.tabs { margin-top: rem-calc(50); }
	#contact-tabs { border-bottom:4px solid $light-gray; border-top: none; }
	#contact-tabs .tabs-title a { border: none; font-size: rem-calc(24); text-decoration: none !important; }
	#contact-tabs .tabs-title a:hover { color: #333; text-decoration: underline; }

main { 

    h1 { font-size: rem-calc(45); @include breakpoint(small only) { font-size: rem-calc(30) }; }
    h2 { font-size: rem-calc(30); @include breakpoint(small only) { font-size: rem-calc(26) }; } 
    h3 { font-size: rem-calc(45); @include breakpoint(small only) { font-size: rem-calc(22) }; }
    h4 { font-size: rem-calc(20); @include breakpoint(small only) { font-size: rem-calc(18) }; } 
    h5 { font-size: rem-calc(16); @include breakpoint(small only) { font-size: rem-calc(17) }; }
    
	ul {
	    list-style: none;
	    margin-left: rem-calc(80);
	    margin-bottom: rem-calc(40);

	    @include breakpoint(small only) { margin-left: rem-calc(40); };
	}
	ul li {
	    background: url(/assets/img/bullets.png) 0px 9px no-repeat;
	    padding-left:20px;
	    margin-top:30px;
	}
	h3, h2 {
	    margin-top: 1em;
	    margin-bottom:0.3em;
	}
	h2:first-of-type { margin-top: 0; }
	p { margin-bottom:1.3em; font-size: rem-calc(17); }
} 

.main-content {
	h1,h2,h3 {  margin-bottom: 1rem; }
	h2 { margin-top: 2rem; }
	a.button { background-color: $cream; }
}

/*-----  HTML specific  -----*/

p:last-of-type { margin-bottom: 0; }
	p.big { font-size: rem-calc(30); line-height: 1.3; @include breakpoint(small only) {font-size: rem-calc(18)}; }

.stack-margin { @include breakpoint(medium down) { margin-top: rem-calc(20); }; }