.top-bar { padding-bottom: 0 !important; }
.top-bar-left { width:100% !important;  }
.menu-centered { display:inline-block; margin: 0 auto !important; }
.top-bar, .top-bar ul { padding-right: 0px !important; }
.menu-centered li { display:inline-block !important; font-size: rem-calc(18); font-family: $roboto !important; margin-left:15px; }
.menu-centered li.divider { display: none !important; }
.menu-centered li:after {
	content: '';
	display: block;
	margin: auto;
	height: 3px;
	width: 0;
	background: rgba(0, 0, 0, 0);
	transition: width .5s ease,background-color .5s ease;
	margin-top: 3px;
}
.menu-centered li.active:after, .menu-centered li:hover:after { width: 100%; background: rgba($light-gray, 1); }
.menu-centered li a, .menu .active > a { color: $green !important; background: transparent !important; text-decoration: none !important; padding: 0px !important; text-transform: uppercase; }
.menu .active > a, .menu-centered li a:hover { text-decoration: none !important; }
ul.menu.submenu {
    border-top: 5px solid #fff;
    padding: 15px 20px!important;
    z-index: 1000;

	li{
	    margin:5px 0; 
	    text-align:left;

	    a{
    		color:#fff !important;
		}
	}

}
.dropdown.menu > li.is-dropdown-submenu-parent > a::after { display:none !important; }

.tabs { background: transparent; border: none; border-bottom: 4px solid #E1E1E1; @extend .martop60; } 
.tabs-content{ background: transparent; border: none; } 
.tabs-title > a { background: transparent; color: #333; font-size: rem-calc(24); border-left: 4px solid #E1E1E1; }
.tabs-title:first-of-type > a { border-left: none; }
.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] { background: #f3f3f3; }
.tabs-title > a:hover { background: #f3f3f3; }
.tabs-panel { display: none !important; opacity: 0; transition: opacity 0.2s ease-in-out; }
.tabs-panel.is-active { display: block !important; opacity: 1; }

.thumbnail { margin-bottom: 30px !important; }
.reveal { top: 50px !important; } 

.button { 
  font-family: $caveat;
	border-radius: 4px; 
	font-weight: bold; 
	text-transform: none; 
	margin-bottom: 0; 
	text-decoration:none !important;
  background-color: $white; 
  color: $green;
  border-radius: 3px;

	@include breakpoint(small) { font-size: rem-calc(26) !important; padding: rem-calc(7 10); }
	@include breakpoint(medium) { font-size: rem-calc(28) !important; padding: rem-calc(7 10); }
	@include breakpoint(large) { font-size: rem-calc(30) !important; padding: rem-calc(7 10); }
}

.red.button { background-color: $white; color: $green !important; }  
.red.button:hover { background-color: scale-color($white, $lightness: 15%); color: $green; }

.redbutton .button { background-color: $white; color: $green !important; } 
.redbutton .button:hover { background-color: scale-color($white, $lightness: -15%); color: $green; }


li.accordion-item {
    margin-bottom: 30px;
    border: none;
    border-radius:10px;
}

li.accordion-item .accordion-title {
    border:none!important;
    border-radius:10px;
    background-color: #3D4B15;
    text-align: left !important;
    color:#fff !important;
    font-size: 35px;
    font-family: caveat;
    text-decoration: none !important;
}
li.accordion-item:first-child > :first-child,:last-child:not(.is-active) > .accordion-title { border-radius:10px!important; }
.accordion-title::before {top: 1rem !important; font-size: 3rem;}

.accordion-content,:last-child > .accordion-content:last-child {
    border:none!important;
    text-align:left;
}


ul.accordion {
    margin-top: 30px;
}

blockquote, blockquote p { line-height: 1.17 !important; color: $green !important; font-size: rem-calc(35); Font-family: $calisto; @include breakpoint(small only) {font-size: rem-calc(26)};}
blockquote {
	border-left: 0px solid $cream !important;
	padding: 0.5625rem 1.25rem 0.5625rem 1.1875rem;

	@include breakpoint(small) { margin: rem-calc(15 20 15); }
	@include breakpoint(medium) { margin: rem-calc(15 20 15); }
	@include breakpoint(large) { margin: rem-calc(30 40 30); }
}  
hr {
	border-bottom: 3px solid $light-gray !important;
	
	&.fourcols { max-width: 25% !important; margin: 50px auto; }
}
.no-js {
  @include breakpoint(small only) {
	.top-bar {
	  display: none;
	}
  }

  @include breakpoint(medium) {
	.title-bar {
	  display: none;
	}
  }
}