.threeblox {
	h4 {  padding: 13px 0px; margin-bottom: 0; text-align: center; font-weight:normal; color:$green; font-family: $caveat !important; @include breakpoint(medium up) { font-size: rem-calc(40); };@include breakpoint(small) { font-size: rem-calc(28); }; }
	img { max-width: 100%; }  
	p { backgorund: $white; padding:20px; text-align: center; font-size: rem-calc(17); transition: all .4s ease-in; } 
	div.hover { cursor: pointer; }
	div.hover a { display: block; color: $body-font-color; text-decoration:none !important; }
	div.hover:hover p.hover { 
		background: #e5e5e5;
		background: -webkit-linear-gradient(#e5e5e5 0%, #ffffff 100%);
		background: -o-linear-gradient(#e5e5e5 0%, #ffffff 100%);
		background: linear-gradient(#e5e5e5 0%, #ffffff 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 );
	}
}


p.newstitle, p.chatter {
	font-size: rem-calc(28);
	text-align: left;
	padding-right: 30px; 
	margin-left: 33%;
}

.green p {
    color: $green;
    padding-top: 10px;
}

body.homepage {
	h2 { margin-bottom: 30px; }

	.seasonal-overlay {
	    position: absolute;
	    bottom: 25px;
	    max-width: 80%;
	    left: 10%;
	    color: white;
	}
	p.quarter {
	    font-size: rem-calc(30);
	    color: #fff;
	    position: absolute;
	    bottom: 10px;
	    left: 50px;
	}
}



