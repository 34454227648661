.parallax {
	background-image: url(/assets/img/local-suppliers.jpg);
	background-attachment: fixed;
	background-position: 50%;
	background-repeat: no-repeat;
	height: auto;
	position: relative;
	background-size:cover;

	h2 { color: $white !important; } 
	p {
		color: $white;
		padding: rem-calc(20 0);

	@include breakpoint(small) { font-size: rem-calc(18) };
	@include breakpoint(medium) { font-size: rem-calc(24) };
	@include breakpoint(large) { font-size: rem-calc(30) };
	}
}


footer{
	border-top: 10px solid $white; 
	background-color: $green; 
	.bgcream { margin-top: rem-calc(10); 
	
	}
	.social a {
    margin-left: 17px;
}
	p { color: $white; font-family: $roboto; font-size: 106.25%; }
	h3 { color: $white !important; text-align: center; font-family: $roboto; }
	h4 { color: $white !important; font-family: $roboto !important; }
	
	& > div:last-of-type { background-color: $white;
		p { font-size: rem-calc(15); margin: 15px 0; color: $green; } 
		a { color: $green !important; text-decoration: underline; };
		a:hover { color: $green; }
	} 

}
